import "./Contact.scss";
import Line from "./../../Assets/Line.svg";
import Mail2 from "./../../Assets/Mail2.svg";
import Phone2 from "./../../Assets/Phone2.svg";
import Location from "./../../Assets/Location.svg";
import emailjs from "@emailjs/browser";
import CircularProgress from "@mui/material/CircularProgress";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t, i18n } = useTranslation();
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      from_name: form.current.user_name.value,
      to_name: "AsusApps",
      message: form.current.message.value,
      user_email: form.current.user_email.value,
      subject: form.current.subject.value,
    };

    emailjs
      .sendForm("service_rgbcmef", "template_u91f5sh", form.current, {
        publicKey: "P7UnPzDTasOjZIceV",
        ...formData,
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setLoading(false);
          setSuccess(true);
          form.current.reset(); // Call the reset function
        },
        (error) => {
          console.log("FAILED...", error.text);
          setLoading(false);
          setFailed(true);
        }
      );
  };

  return (
    <div className="contact">
      {success && (
        <article className="message">
          <article className="message-container">
            <img
              src="https://res.cloudinary.com/dmymq7zql/image/upload/v1710663962/vbrubocwxut3ntt3hwgv.gif"
              alt=""
              className="tot"
            />
            <h2>شكراً لك!</h2>
            <p>تم تلقي طلبك بنجاح سوف يتم التواصل معك في اقرب وقت</p>
            <button type="button" onClick={() => setSuccess(false)}>
              حسناً
            </button>
          </article>
        </article>
      )}
      {failed && (
        <article className="message">
          <article className="message-container">
            <img
              src="https://res.cloudinary.com/dmymq7zql/image/upload/v1710665242/kib6xlaltuhfaza3luqp.gif"
              alt=""
              className="tot"
            />
            <h2>معذرةً!</h2>
            <p>حدث خطاء ما برجاء المحاولة مرة اخري</p>
            <button type="button" onClick={() => setFailed(false)}>
              حسناً
            </button>
          </article>
        </article>
      )}
      <div className="container">
        <div className="special-heading">
          <img src={Line} alt="" />
          <h1>{t("contact")}</h1>
          <p>{t("main_title")}</p>
        </div>
        <div className="wrapper" style={{ gap: "24px" }}>
          <div className="right">
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=أسس+الحلول+لتقنيه+المعلومات،+Imam+Abdullah+Ibn+Saud+Ibn+Abdulaziz+Road,+Riyadh+Saudi+Arabia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              title="location"
              allowFullScreen
              loading="lazy"
              className="google-map"
            />
            <div className="items-container">
              <div className="item">
                <img src={Mail2} alt="" />
                <a
                  className="text"
                  href="mailto:info@asusapps.com"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                  rel="noreferrer"
                >
                  <span>{t("mail")}</span>
                  <span>info@asusapps.com</span>
                </a>
              </div>
              <div className="item">
                <img src={Phone2} alt="" />
                <a
                  className="text"
                  href="tel:+966 55 744 0114"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                  rel="noreferrer"
                >
                  <span>{t("phone")}</span>
                  <span dir="ltr">+966 55 744 0114</span>
                </a>
              </div>
              <div className="item">
                <img src={Location} alt="" />
                <div className="text">
                  <span>{t("address")}</span>
                  <span>
                    <a
                      href="https://www.google.com/maps/dir//%D8%A3%D8%B3%D8%B3+%D8%A7%D9%84%D8%AD%D9%84%D9%88%D9%84+%D9%84%D8%AA%D9%82%D9%86%D9%8A%D9%87+%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA+RQ3J%2B8XV+Imam+Abdullah+Ibn+Saud+Ibn+Abdulaziz+Rd,+Al+Yarmuk+Riyadh+13242,+Saudi+Arabia%E2%80%AD%E2%80%AD/@24.803888,46.7821655,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3e2eff1a341782df:0x102b4c37c3d02635!2m2!1d46.7821655!2d24.803888?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      اليرموك الرياض
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="left">
            <form ref={form} onSubmit={sendEmail}>
              <p>{t("lets_start")}</p>
              <input
                type="text"
                name="user_name"
                placeholder={t("name")}
                required
              />
              <input
                type="email"
                name="user_email"
                placeholder={t("email")}
                required
              />
              <input
                type="text"
                name="subject"
                placeholder={t("message_title")}
                required
              />
              <textarea
                name="message"
                placeholder={t("message")}
                cols="30"
                rows="10"
                required
              ></textarea>
              <button type="submit" value="Send">
                {loading ? <CircularProgress size={30} /> : t("send")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
