import "./Landing.scss";
import LandingImg from "./../../Assets/LandingImg.jpg";
// import LandingImg from "./../../Assets/LandingImg2.png";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Landing = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="landing">
      <div className="container">
        <div
          className="right"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
        >
          <Fade direction={i18n.language === "ar" ? "right" : "left"}>
            <h1 className="title">
              <span>{t("welcome")}</span>
              <span>{t("to_Asus_Solutions")}</span>
            </h1>
            <p className="description">{t("landing_content")}</p>
            <div className="btn-container">
              {/* <ScrollTo to="services" smooth={true} duration={500}>
                <button className="btn-one">{t("more")}</button>
              </ScrollTo>
              <ScrollTo to="contact" smooth={true} duration={500}>
                <button className="btn-two">{t("contact")}</button>
              </ScrollTo> */}
              {/* <button> */}
              <Link
                target="_blank_whatsapp"
                className="btn-one"
                to={"https://wa.me/966557440114"}
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("Free_consultation")}
              </Link>
              {/* </button> */}
            </div>
          </Fade>
        </div>

        <div className="left">
          <Fade direction={i18n.language === "ar" ? "left" : "right"}>
            <img src={LandingImg} alt="" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Landing;
