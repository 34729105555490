import "./Portfolio.scss";
import Slider1 from "./../../Assets/Slider1.svg";
import Slider2 from "./../../Assets/Slider2.svg";
import Slider3 from "./../../Assets/Slider3.svg";
import Slider4 from "./../../Assets/Slider4.svg";
import askmeLogo from "./../../Assets/askmeLogo.png";
import askmeMock from "./../../Assets/askmeMock.svg";
import oscarLogo from "./../../Assets/oscarLogo.png";
import oscarMock from "./../../Assets/oscarMock.svg";
import ejazahLogo from "./../../Assets/ejazahLogo.png";
import ejazahMock from "./../../Assets/ejazahMock.svg";
import menuLogo from "./../../Assets/menuLogo.png";
import menuMock from "./../../Assets/menuMock.svg";
import Line from "./../../Assets/Line.svg";
import { useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Portfolio = () => {
  const { t, i18n } = useTranslation();
  const [slider, setSlider] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const sliderItems = [
    {
      img: Slider1,
      key: 1,
      name: "askme",
      mock: askmeMock,
      logo: askmeLogo,
      text: t("ask_me_text"),
    },
    {
      img: Slider2,
      key: 2,
      name: "oscar",
      mock: oscarMock,
      logo: oscarLogo,
      text: t("oscar_text"),
    },
    {
      img: Slider3,
      key: 3,
      name: "menu",
      mock: menuMock,
      logo: menuLogo,
      text: t("menu_text"),
    },
    {
      img: Slider4,
      key: 4,
      name: "ejazah",
      mock: ejazahMock,
      logo: ejazahLogo,
      text: t("ejazah_text"),
    },
  ];
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="portfolio">
        <div className="container">
          <div className="special-heading">
            <img src={Line} alt="" />
            <h1>{t("portfolio")}</h1>
            <p>{t("main_title")}</p>
          </div>
          <div dir="ltr" className="crsl">
            <Carousel
              autoPlay
              infiniteLoop
              centerMode
              interval={3000}
              transitionTime={500}
              showArrows={true}
              showThumbs={false}
              showStatus={false}
            >
              {sliderItems.map((slider) => (
                <div
                  className="crsl-item"
                  dir="ltr"
                  key={slider.key}
                >
                  <VisibilityIcon
                    className="view-icon"
                    onClick={() => {
                      setSlider(slider);
                      setOpen(true);
                    }}
                  />
                  <img src={slider.img} alt="" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <div className="container">
          <CancelOutlinedIcon className="close-icon" onClick={handleClose} />
          <img src={slider?.logo} className="logo" alt="" />
          <p className="text">{slider?.text}</p>
          {!isLoaded && <CircularProgress size={60} />}
          <img
            src={slider?.mock}
            className="mock"
            alt=""
            onLoad={handleImageLoad}
            style={{ display: isLoaded ? "block" : "none" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Portfolio;
