import { useTranslation } from "react-i18next";
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [direction, setDirection] = useState(
    localStorage.getItem("direction") || "rtl"
  );
  const [t, i18n] = useTranslation();

  // Function to change the language
  const changeLanguage = (lang) => {
    if (lang === "SA") {
      setDirection("rtl");
      window.document.documentElement.lang = "ar";
      window.document.documentElement.dir = "rtl";
      localStorage.setItem("direction", "rtl");
      i18n.changeLanguage("ar");
    } else {
      setDirection("ltr");
      window.document.documentElement.lang = "en";
      window.document.documentElement.dir = "ltr";
      localStorage.setItem("direction", "ltr");
      i18n.changeLanguage("en");
      console.log("en");
    }
  };

  // Load the initial direction from localStorage on component mount
  useEffect(() => {
    // const storedDirection = localStorage.getItem("direction");
    if (direction) {
      console.log("storedDirection", direction);

      changeLanguage(direction === "ltr" ? "en" : "SA");
    }
  }, []);

  // Values to be provided to the consumer components
  const values = {
    direction,
    changeLanguage,
  };

  return (
    <LanguageContext.Provider value={values}>
      {children}
    </LanguageContext.Provider>
  );
};
