import { useContext, useEffect } from "react";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Landing from "./Pages/Landing/Landing";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Services from "./Pages/Services/Services";
import { LanguageContext } from "./context/LanguageContext";

function App() {
  const { direction } = useContext(LanguageContext);

  useEffect(() => {
    document.documentElement.style.setProperty("--direction", direction);
  }, [direction]);
  return (
    <div className="App">
      <a
        className="icon-whatsapp"
        href="https://wa.me/966557440114"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          fill-rule="evenodd"
          clip-rule="evenodd"
          image-rendering="optimizeQuality"
          shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision"
          viewBox="0 0 13333.33 13333.33"
          id="whatsapp"
        >
          <path
            fill="#3c3"
            d="M6668.43 10621.66c-809.34,0 -1559.74,-243.52 -2191.57,-654.83l-1526.58 483.73 493.6 -1470.81c-477.12,-644.87 -760.15,-1454.33 -760.15,-2319.68 0,-128.34 9.84,-250.07 26.31,-378.39 194.15,-2003.85 1898.59,-3570.02 3958.39,-3570.02 2092.6,0 3813.46,1608.96 3974.57,3642.41 6.61,105.26 6.61,210.54 6.61,305.99 0,2181.5 -1786.44,3961.59 -3981.19,3961.59z"
          ></path>
          <path
            fill="#fff"
            d="M8050.36 7196.37c-115.4,-29.61 -187.6,-55.93 -269.89,65.83 -118.55,187.54 -368.57,598.83 -611.98,477.1 -121.7,-55.93 -493.43,-177.7 -937.68,-572.54 -345.56,-309.3 -582.34,-681.09 -645.08,-792.96 -101.84,-190.85 220.39,-391.56 338.94,-631.75 39.41,-78.98 23.02,-144.78 -9.78,-200.72 -23.02,-55.93 -256.65,-628.44 -355.33,-855.48 -95.54,-233.63 -194.22,-194.12 -256.65,-194.12 -645.09,0 -947.76,457.36 -947.76,1098.94 0,128.32 26.48,266.55 65.89,388.28 115.08,411.3 371.73,743.61 411.45,799.55 55.81,78.94 799.58,1276.65 1980.66,1737.35 1187.7,460.58 1187.7,305.96 1398.32,289.47 207.46,-16.37 684.5,-273.11 783.18,-549.49 95.54,-266.48 95.54,-500.15 62.43,-549.46 -39.41,-69.08 -1006.72,-506.7 -1006.72,-510.01z"
          ></path>
        </svg>
      </a>
      <Navbar />
      <Landing />
      <Services />
      <Portfolio />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
