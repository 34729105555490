import "./About.scss";
import { Fade } from "react-awesome-reveal";
import Line from "./../../Assets/Line.svg";
import image1 from "./../../Assets/slider1.jpg";
import image2 from "./../../Assets/slider2.jpg";
import image3 from "./../../Assets/slider3.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();
  const images = [image1, image2, image3];

  return (
    <div className="about">
      <div className="container">
        <div className="right">
          <Fade direction={"right"}>
            <img src={Line} alt="" />
            <h2>{t("about")}</h2>
            <p className="description">{t("about_content")}</p>
          </Fade>
        </div>
        <div className="left" dir="ltr">
          <Fade direction={"left"}>
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              showArrows={false}
            >
              {images.map((img, index) => (
                <div key={index}>
                  <img src={img} alt="" />
                </div>
              ))}
            </Carousel>
            {/* <img src={image1} alt="who we are" className="image" /> */}
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default About;
