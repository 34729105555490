import "./Footer.scss";
import Logo from "./../../Assets/logo.png";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div className="right">
          <img src={Logo} alt="" />
          <p>{t("app_title")}</p>
        </div>
        <div className="left">
          <ul>
            <ScrollTo to="landing" smooth={true} duration={500}>
              <li>{t("home")}</li>
            </ScrollTo>
            <ScrollTo to="about" smooth={true} duration={500}>
              <li>{t("about")}</li>
            </ScrollTo>
            <ScrollTo to="services" smooth={true} duration={500}>
              <li>{t("services")}</li>
            </ScrollTo>
            <ScrollTo to="portfolio" smooth={true} duration={500}>
              <li>{t("portfolio")}</li>
            </ScrollTo>
            <ScrollTo to="contact" smooth={true} duration={500}>
              <li>{t("contact")}</li>
            </ScrollTo>
          </ul>

          <div className="social">
            <div>
              <Link
                target="_blank_fb"
                to={"https://www.facebook.com/asusalhlwl"}
              >
                <FontAwesomeIcon icon={faFacebook} />{" "}
              </Link>
              <Link
                target="_blank_insta"
                to={"https://www.instagram.com/asus__app/"}
              >
                <FontAwesomeIcon icon={faInstagram} />{" "}
              </Link>
              <Link
                target="_blank_youtube"
                to={"https://www.youtube.com/@asus__app"}
              >
                <FontAwesomeIcon icon={faYoutube} />{" "}
              </Link>
              <Link
                target="_blank_tiktok"
                to={"https://www.tiktok.com/@asus__app"}
              >
                <FontAwesomeIcon icon={faTiktok} />{" "}
              </Link>
              <Link
                target="_blank_linkedin"
                to={"https://www.linkedin.com/company/asus-alhlwl/"}
              >
                <FontAwesomeIcon icon={faLinkedin} />{" "}
              </Link>
              <Link
                target="_blank_twitter"
                to={"https://twitter.com/asus__app"}
              >
                <FontAwesomeIcon icon={faXTwitter} />{" "}
              </Link>
            </div>
            <div>
              <Link target="_blank_email" to={"mailto:info@asusapps.com"}>
                <FontAwesomeIcon icon={faEnvelope} />{" "}
              </Link>
              <Link target="_blank_whatsapp" to={"https://wa.me/966557440114"}>
                <FontAwesomeIcon icon={faWhatsapp} />{" "}
              </Link>

              <Link target="_blank_call" to={"tel:+966557440114"}>
                <FontAwesomeIcon icon={faPhone} />{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <p>{t("copy")}</p>
    </div>
  );
};

export default Footer;
