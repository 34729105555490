import "./Navbar.scss";

import Logo from "./../../Assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import ReactFlagsSelect from "react-flags-select";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";

const pages = [
  {
    title: "home",
    path: "landing",
  },
  {
    title: "about",
    path: "about",
  },
  {
    title: "services",
    path: "services",
  },
  {
    title: "portfolio",
    path: "portfolio",
  },
  {
    title: "contact",
    path: "contact",
  },
];
const Navbar = () => {
  const { changeLanguage } = useContext(LanguageContext);
  const Lang = localStorage.getItem("i18nextLng");
  const { t, i18n } = useTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className="navbar">
      {/* Start Wedgets  */}
      <div className="social">
        <Link target="_blank_fb" to={"https://www.facebook.com/asusalhlwl"}>
          <FontAwesomeIcon icon={faFacebook} />{" "}
        </Link>
        <Link target="_blank_insta" to={"https://www.instagram.com/asus__app/"}>
          <FontAwesomeIcon icon={faInstagram} />{" "}
        </Link>
        <Link target="_blank_youtube" to={"https://www.youtube.com/@asus__app"}>
          <FontAwesomeIcon icon={faYoutube} />{" "}
        </Link>
        <Link target="_blank_tiktok" to={"https://www.tiktok.com/@asus__app"}>
          <FontAwesomeIcon icon={faTiktok} />{" "}
        </Link>
        <Link
          target="_blank_linkedin"
          to={"https://www.linkedin.com/company/asus-alhlwl/"}
        >
          <FontAwesomeIcon icon={faLinkedin} />{" "}
        </Link>
        <Link target="_blank_twitter" to={"https://twitter.com/asus__app"}>
          <FontAwesomeIcon icon={faXTwitter} />{" "}
        </Link>
        <Link target="_blank_email" to={"mailto:info@asusapps.com"}>
          <FontAwesomeIcon icon={faEnvelope} />{" "}
        </Link>
        <Link target="_blank_whatsapp" to={"https://wa.me/966557440114"}>
          <FontAwesomeIcon icon={faWhatsapp} />{" "}
        </Link>

        <Link target="_blank_call" to={"tel:+966557440114"}>
          <FontAwesomeIcon icon={faPhone} />{" "}
        </Link>
      </div>
      {/* End Wedgets  */}

      {/* Start Header */}
      <div className="header">
        <div className="right">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="left">
          {/* <MenuIcon className="menu-icon" /> */}
          <ul>
            {pages.map((page) => (
              <ScrollTo key={page} to={page.path} smooth={true} duration={500}>
                <li>{t(page.title)}</li>
              </ScrollTo>
            ))}
          </ul>
          {/* Start Menu in Mobile */}
          <Box
            sx={{
              // flexGrow: 1,
              // display: { xs: "flex", md: "none" },
              zIndex: 1000,
            }}
            className="menu-drop"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <>
                {pages.map((page) => (
                  <MenuItem key={page}>
                    <ScrollTo
                      to={page.path}
                      smooth={true}
                      duration={500}
                      onClick={handleCloseNavMenu}
                      style={{ width: "100%" }}
                    >
                      <li>{t(page.title)}</li>
                    </ScrollTo>
                  </MenuItem>
                ))}
                <div
                  className="lang__container"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <ReactFlagsSelect
                    countries={["SA", "US"]}
                    customLabels={{
                      SA: "عربى",
                      US: "انجليزى",
                    }}
                    onSelect={(code) => {
                      changeLanguage(code);
                      handleCloseNavMenu();
                    }}
                    onClick={handleCloseNavMenu}
                    placeholder="Select Language"
                    selected={Lang === "en" ? "US" : "SA"}
                    className="language"
                  />
                </div>
              </>
            </Menu>
          </Box>
          {/* End Menu in Mobile */}
        </div>

        <div className="nav__lang lang__container">
          <ReactFlagsSelect
            countries={["SA", "US"]}
            customLabels={{
              SA: t("ar"),
              US: t("en"),
            }}
            onSelect={(code) => {
              changeLanguage(code);
            }}
            placeholder="Select Language"
            selected={Lang === "en" ? "US" : "SA"}
            className="language"
          />
        </div>
      </div>
      {/* End Header */}
    </div>
  );
};

export default Navbar;
