import "./Services.scss";
import Line from "./../../Assets/Line.svg";
import { Fade } from "react-awesome-reveal";
import Services1 from "./../../Assets/Services1.svg";
import Services2 from "./../../Assets/Services2.svg";
import Services3 from "./../../Assets/Services3.svg";
import Services4 from "./../../Assets/Services4.svg";
import Services5 from "./../../Assets/Services5.svg";
import Services6 from "./../../Assets/Services6.svg";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();
  const items = [
    {
      img: Services1,
      title: t("server_upload"),
      text: t("server_upload_title"),
    },
    {
      img: Services2,
      title: t("web"),
      text: t("web_title"),
    },
    {
      img: Services3,
      title: t("app"),
      text: t("head_title"),
    },
    {
      img: Services4,
      title: t("erp"),
      text: t("erp_title"),
    },
    {
      img: Services5,
      title: t("user_interface"),
      text: t("user_interface_title"),
    },
    {
      img: Services6,
      title: t("support"),
      text: t("support_title"),
    },
  ];
  return (
    <div className="services">
      <div className="container">
        <div className="special-heading">
          <img src={Line} alt="" />
          <h1>{t("services")}</h1>
          <p>{t("main_title")}</p>
        </div>
        <div className="items-container">
          {items.map((item) => (
            <Fade direction={"top-right"} className="item" key={item.title}>
              <div className="item-top">
                <img src={item?.img} alt="" />
                <div className="item-bottom">
                  <h3>{item?.title}</h3>
                  <p>{item?.text}</p>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
